<template>
  <div>
    <b-card no-body>
      <b-card-body class="px-0 pb-0">
        <b-row>
          <b-col cols="12">
            <div
              :class="[
                'pb-2 px-2 d-flex justify-content-between',
                visible ? 'border-bottom' : '',
              ]"
            >
              <div class="d-flex">
                <h4 class="mb-0 mr-1">Clientes para ligar</h4>
                <div class="type-interview">
                  <h6>
                    <span>{{ total }}</span>
                  </h6>
                </div>
              </div>

              <div class="type-interview d-flex">
                <!-- <h6>
                  <span>{{ total }}</span>
                </h6> -->
                <button
                  class="btn"
                  :aria-expanded="visible ? 'true' : 'false'"
                  aria-controls="collapse-1"
                  @click="visible = !visible"
                >
                  <feather-icon
                    :icon="visible ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                    class="mouse-pointer"
                    size="18"
                    stroke-width="3"
                  />
                </button>
              </div>
            </div>
          </b-col>
          <b-collapse v-model="visible" id="collapse-1" class="w-100">
            <b-col cols="12">
              <section class="container-schedule">
                <div
                  v-if="loading.customers"
                  class="h-100 d-flex align-items-center justify-content-center p-2"
                >
                  <b-spinner variant="primary" />
                </div>
                <div
                  v-if="!customers.length && !loading.customers"
                  class="h-100 d-flex align-items-center justify-content-center p-2"
                >
                  <span class="text-center"
                    >Não existem clientes para ligar hoje</span
                  >
                </div>
                <div
                  v-if="customers.length && !loading.customers"
                  :class="[
                    'box-schedule h-100',
                    useScrolled ? 'scrolled' : '',
                    `scroll-${skin}`,
                  ]"
                >
                  <article v-for="(customer, index) in customers" :key="index">
                    <div
                      class="d-flex justify-content-between align-items-center border-bottom p-2 dash-customer"
                    >
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <div class="profile-picture">
                          <b-nav
                            class="d-flex align-items-center justify-content-between mb-0"
                            pills
                          >
                            <b-nav-item class="nav-btn bg-gray" active>
                              <div
                                class="d-flex flex-column align-items-center"
                              >
                                <h4 class="text-weight">
                                  {{ formatDate(getToday()).day }}
                                </h4>
                                <span>{{ formatDate(getToday()).month }}</span>
                              </div>
                            </b-nav-item>
                          </b-nav>
                        </div>
                        <div class="main-box d-flex align-items-center">
                          <div
                            class="name-content w-auto"
                            style="cursor: pointer; max-width: "
                          >
                            <b-badge
                              variant="lead-name"
                              class="name"
                              @click="goToDetail(customer.id)"
                            >
                              <div
                                class="align-text-right text-capitalize text-wrap"
                                style="margin-bottom: 4px"
                              >
                                {{ customer.name }}
                              </div>
                              <div
                                v-if="customer.cell_phone"
                                class="align-text-top phone"
                              >
                                {{ customer.cell_phone | formatPhone }}
                              </div>
                            </b-badge>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex align-items-center">
                        <div class="schedule-time">
                          {{ customer.call_reminder_hour }}
                        </div>
                        <b-badge
                          variant="lead-name"
                          class="whats"
                          @click="openWhatsapp(customer.cell_phone)"
                        >
                          <img
                            src="~@/assets/images/svg/icn_whats.svg"
                            width="18"
                          />
                        </b-badge>
                      </div>
                    </div>
                  </article>
                </div>
              </section>
            </b-col>
            <!-- <b-col cols="12">
              <div class="p-2 pb-0 d-flex justify-content-end border-top">
                <a style="color: #f26237" @click="goToDetails">
                  Ver detalhes &#10095;
                </a>
              </div>
            </b-col> -->
            <b-col cols="12">
              <div class="py-1 d-flex justify-content-end border-top">
                <b-button variant="link" @click="goToDetails">
                  Ver detalhes &#10095;
                </b-button>
              </div>
            </b-col>
          </b-collapse>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BBadge, BCol, BCollapse, BRow, BCard, BCardBody } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import { OWN, STRUCTURE, DIRECT } from "@/constants/structure_options";

export default {
  components: {
    BBadge,
    BCol,
    BCollapse,
    BRow,
    BCard,
    BCardBody,
  },
  data() {
    return {
      visible: true,
      loading: {
        customers: false,
      },
    };
  },

  setup() {
    const { skin } = useAppConfig();

    return { skin };
  },

  computed: {
    ...mapGetters({
      customersReminder: types.CUSTOMERS_REMINDER,
    }),

    customers() {
      return this.customersReminder?.customers || [];
    },

    total() {
      return this.customersReminder?.total || 0;
    },
    useScrolled() {
      return (
        this.customers.length > 3 || this.$store.state.app.windowWidth < 1380
      );
    },
  },

  mounted() {
    this.fetchCustomersReminder();
  },

  methods: {
    ...mapActions({
      getCustomersReminder: types.GET_CUSTOMERS_REMINDER,
    }),

    fetchCustomersReminder() {
      this.loading.customers = true;

      this.getCustomersReminder()
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os clientes para ligar. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.customers = false;
        });
    },

    formatDate(dateString) {
      const months = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ];
      const dateParts = dateString.split("/");
      return {
        day: dateParts[0],
        month: months[parseInt(dateParts[1]) - 1],
      };
    },

    formatPhoneNumber(phoneNumberString) {
      let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      let match;
      if (cleaned.length === 13) {
        match = cleaned.match(/^(\d{2})(\d{2})(\d{5})(\d{4})$/);
      } else if (cleaned.length === 12) {
        match = cleaned.match(/^(\d{2})(\d{2})(\d{4})(\d{4})$/);
      }
      if (match) {
        // return (
        //   "+" + match[1] + " (" + match[2] + ") " + match[3] + "-" + match[4]
        // );
        return " (" + match[2] + ") " + match[3] + "-" + match[4];
      }
      return null;
    },

    getToday() {
      const date = new Date();
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    },

    goToDetails() {
      const date = new Date();
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();

      const today = `${year}-${month}-${day}`;

      let routeData = this.$router.push({
        path: "/clientes",
        query: {
          startDate: today,
          endDate: today,
          structureType: OWN,
        },
      });
    },
    goToDetail(id) {
      const routeDetails = this.$router.resolve({
        name: "customer-profile",
        params: { id },
      });
      setTimeout(() => {
        window.open(routeDetails.href, "_blank");
      });
    },
    openWhatsapp(cell_phone) {
      setTimeout(() => {
        window.open("https://wa.me/" + cell_phone, "_blank").focus();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-profile.scss";
@import "@core/scss/views/dashboard/components.scss";

$grey: #636978;

.nav-btn > .nav-link {
  padding: 0.75rem !important;
  cursor: auto;

  &.active {
    background-color: $grey !important;
    border: 1px solid $grey;
    box-shadow: none;

    h4 {
      color: $white;
    }
  }
}

.past-birthdays {
  filter: opacity(0.3);
}

.container-schedule {
  height: 325.77px;

  .user {
    span {
      margin-right: 1rem;
    }
    .type-user {
      margin-right: 0;
    }
  }
}
</style>
