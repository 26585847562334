<template>
  <div>
    <newToDoSidebar />
    <b-card no-body>
      <b-card-body class="px-0 pb-0">
        <b-row>
          <b-col cols="12">
            <div
              :class="[
                'pb-1 px-2 d-flex justify-content-between todo-header',
                visible ? 'border-bottom' : '',
              ]"
            >
              <div class="d-flex align-items-center">
                <h4 class="mb-0 mr-1">TO-DO</h4>
                <div class="type-interview">
                  <h6>
                    <span>{{ totalItems }}</span>
                  </h6>
                </div>
              </div>
              <div class="type-interview d-flex">
                <div class="task-button">
                  <b-button @click="triggerOpenNewToDoSidebar">
                    <feather-icon icon="PlusIcon" class="mr-25" size="14" />
                    <span>Tarefa rápida</span>
                  </b-button>
                </div>
                <button
                  class="btn"
                  :aria-expanded="visible ? 'true' : 'false'"
                  aria-controls="collapse-1"
                  @click="visible = !visible"
                >
                  <feather-icon
                    :icon="visible ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                    class="mouse-pointer"
                    size="18"
                    stroke-width="3"
                  />
                </button>
              </div>
            </div>
          </b-col>
          <b-collapse v-model="visible" id="collapse-1" class="w-100">
            <b-col cols="12">
              <section class="container-todo">
                <div
                  v-if="loading.tasks"
                  class="h-100 d-flex align-items-center justify-content-center p-2"
                >
                  <b-spinner variant="primary" />
                </div>
                <div
                  v-if="!tasks.length && !loading.tasks"
                  class="h-100 d-flex align-items-center justify-content-center p-2"
                >
                  <span class="text-center">
                    Não existem tarefas pendentes para hoje
                  </span>
                </div>
                <div
                  :class="[
                    'box-todo h-100',
                    tasks.length > 7 ? 'scrolled' : '',
                    `scroll-${skin}`,
                  ]"
                >
                  <div v-if="tasks.length && !loading.tasks">
                    <b-list-group flush>
                      <b-list-group-item
                        class="d-flex justify-content-start align-items-center"
                        v-for="task in tasks"
                        :key="task.id"
                        @click="openEditWindow(task)"
                      >
                        <div
                          :id="`check-list-task-div-${task.id}`"
                          class="custom-control custom-checkbox"
                          @click.stop=""
                        >
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            :id="`check-list-task-${task.id}`"
                            @change="toggleTaskCompletion(task.id)"
                          />
                          <label
                            class="custom-control-label"
                            :for="`check-list-task-${task.id}`"
                            style="cursor: pointer !important"
                          ></label>
                        </div>
                        <b-tooltip
                          triggers="hover"
                          :target="`check-list-task-div-${task.id}`"
                          title="Marcar como concluída"
                        />
                        <div class="email-favorite ml-1">
                          <feather-icon
                            icon="StarIcon"
                            v-if="task.is_important"
                            size="17"
                            class="text-warning fill-current"
                            v-b-tooltip.hover
                            title="Importante"
                          />
                        </div>
                        <div class="flex-grow-1 ml-1">
                          <div>
                            <h5>{{ task.title }}</h5>
                          </div>
                          <h6>{{ task.task_start }}</h6>
                        </div>
                        <div class="ml-auto" v-if="!task.is_full_day">
                          <h6>{{ task.task_hour }}</h6>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                    <div v-if="totalItems > 10" class="m-2">
                      <b-row>
                        <b-col
                          cols="12"
                          sm="6"
                          class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                          <span class="text-muted"
                            >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
                            {{ totalItems }}</span
                          >
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                          cols="12"
                          sm="6"
                          class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                          <b-pagination
                            :value="currentPage"
                            :total-rows="totalItems"
                            :per-page="itemsPerPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                            @change="fetchTasksFromToday"
                          >
                            <template #prev-text>
                              <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                              <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                          </b-pagination>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </section>
            </b-col>
            <b-col cols="12">
              <div class="py-1 d-flex justify-content-end border-top">
                <b-button variant="link" :to="{ name: 'tasks' }">
                  Ver detalhes &#10095;
                </b-button>
              </div>
            </b-col>
          </b-collapse>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BTooltip } from "bootstrap-vue";
import useAppConfig from "@core/app-config/useAppConfig";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as types from "../store/types";
import NewToDoSidebar from "../components/NewToDoSidebar.vue";

export default {
  components: {
    BTooltip,
    NewToDoSidebar,
  },
  data() {
    return {
      daysOfWeek: [],
      activeDayIndex: null,
      activeDate: null,
      weekOffset: 0,
      visible: true,
      loading: {
        tasks: false,
      },
      currentPage: 1,
      itemsPerPage: 10,
    };
  },

  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
      toast: useToast(),
    };
  },

  computed: {
    ...mapGetters({
      tasksFromToday: types.TASKS_FROM_TODAY,
    }),
    tasks() {
      return this.tasksFromToday.data || [];
    },
    totalItems: function () {
      return this.tasksFromToday?.total || 0;
    },
    pageItemsFrom: function () {
      return this.tasksFromToday?.from || 0;
    },
    pageItemsTo: function () {
      return this.tasksFromToday?.to || 0;
    },
  },

  mounted() {
    this.fetchTasksFromToday();
  },

  methods: {
    ...mapActions({
      getTasksFromToday: types.GET_TASKS_FROM_TODAY,
      openNewToDoSidebar: types.OPEN_NEW_TO_DO_SIDEBAR,
      markAsCompleted: types.MARK_AS_COMPLETED,
    }),
    openEditWindow(task) {
      this.openNewToDoSidebar({
        id: task.id,
        saveAction: this.fetchTasksFromToday,
        visible: true,
      });
    },
    triggerOpenNewToDoSidebar() {
      this.openNewToDoSidebar({
        id: undefined,
        saveAction: this.fetchTasksFromToday,
        visible: true,
      });
    },
    toggleTaskCompletion(taskId) {
      this.loading.tasks = true;
      this.markAsCompleted(taskId).then(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Sucesso",
            text: "A operação foi concluida com sucesso!",
            icon: "CoffeeIcon",
            variant: "success",
          },
        });
        this.fetchTasksFromToday();
      });
    },
    fetchTasksFromToday(currentPage) {
      this.loading.tasks = true;
      this.currentPage = Number.isInteger(currentPage)
        ? currentPage
        : this.currentPage;
      this.getTasksFromToday({
        currentPage: this.currentPage,
        limit: this.itemsPerPage,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar as tasks. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.tasks = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-profile.scss";
@import "@core/scss/views/dashboard/components.scss";

.dark-layout .profile-nav {
  background-color: $theme-dark-card-bg !important;
}
.profile-nav {
  width: 15rem;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  background-color: #ffff;
}

.nav-bar ul {
  margin-bottom: 0 !important;
}

.nav-btn > .nav-link {
  padding: 0.75rem !important;

  &.active {
    background-color: #636978 !important;
    border: 1px solid #636978;
    box-shadow: none;

    h4 {
      color: #fff;
    }
  }
}

.container-todo {
  height: 579px !important;
}

.box-datecard {
  padding: 1.5rem;
}

.side-dark {
  background-color: #0a0d14;
}

.side-light {
  background-color: #fff;
}

.side-btn {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  border: 1px solid #0a0d14;
  color: #f26237;
}

.type-interview {
  h6 {
    margin-bottom: 0;
    margin-right: 0.75rem;
    display: flex;
    align-items: center;
    &:last-child {
      margin-right: 0;
    }

    span {
      color: #f26237;
      background-color: #6d4042;
      padding: 0.15rem 1rem;
      border-radius: 20px;
      font-weight: bold;
      margin-left: 0.25rem;
    }
  }
  .task-button {
    button {
      // padding: 8px 25px 8px 25px !important;
      background-color: #f26237 !important;
      border-color: #f26237 !important;
      box-shadow: 0 4px 18px -4px rgba(242, 98, 55, 0.65) !important;
      padding: 0.786rem 1.5rem;
      border: 1px solid transparent;
      border-radius: 0.358rem;
      font-size: 1rem;
      font-weight: normal;
      line-height: 1rem;
    }
  }
}

.border-bottom {
  border-bottom: 1px solid #181d2f;
}

.bg-date-dark {
  background-color: #181d2f;
}

.bg-date-light {
  background-color: #f8f8f8;
}

.text-weight {
  font-weight: bold;
}

.scroll-dark {
  &::-webkit-scrollbar-track {
    background: #181d2f;
  }
  &::-webkit-scrollbar-thumb {
    border: 3px solid #181d2f;
  }
}

.scroll-light {
  &::-webkit-scrollbar-track {
    background: #f8f8f8;
  }
  &::-webkit-scrollbar-thumb {
    border: 3px solid #f8f8f8;
  }
}

.container-todo {
  height: 325.77px;
  .box-todo {
    height: 325.77px;
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #f26237;
      border-radius: 20px;
    }
    .main-box {
      margin-left: 1rem;

      .title {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        margin-bottom: 0.5rem;
      }

      .user {
        .type-user {
          margin-left: 1rem;
          padding: 0.15rem 1rem;
          background: #327264;
          border-radius: 20px;
          color: #eaeaea;
          font-size: 0.85rem;
        }
      }
    }
  }
}
</style>
