<template>
  <div>
    <b-card no-body>
      <b-card-body class="px-0 pb-0">
        <b-row>
          <b-col cols="12">
            <div
              :class="[
                'pb-2 px-2 d-flex justify-content-between',
                visible ? 'border-bottom' : '',
              ]"
            >
              <h4 class="mb-0">Aniversários do mês</h4>
              <div class="type-interview d-flex">
                <button
                  class="btn"
                  :aria-expanded="visible ? 'true' : 'false'"
                  aria-controls="collapse-1"
                  @click="visible = !visible"
                >
                  <feather-icon
                    :icon="visible ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                    class="mouse-pointer"
                    size="18"
                    stroke-width="3"
                  />
                </button>
              </div>
            </div>
          </b-col>
          <b-collapse v-model="visible" id="collapse-1" class="w-100">
            <b-col cols="12">
              <section class="container-schedule">
                <div
                  v-if="loading.birthdays"
                  class="h-100 d-flex align-items-center justify-content-center p-2"
                >
                  <b-spinner variant="primary" />
                </div>
                <div
                  v-if="!birthdaysLength && !loading.birthdays"
                  class="h-100 d-flex align-items-center justify-content-center p-2"
                >
                  <span class="text-center"
                    >Não existem aniversariantes este mês</span
                  >
                </div>
                <div
                  v-if="birthdaysLength && !loading.birthdays"
                  :class="[
                    'box-schedule h-100',
                    useScrolled ? 'scrolled' : '',
                    `scroll-${skin}`,
                  ]"
                >
                  <article
                    class="future-birthdays"
                    v-for="(birthday, index) in birthdaysFuture"
                    :key="birthday.cell_phone + index"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center border-bottom p-2 dash-customer"
                    >
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <div class="profile-picture">
                          <b-nav
                            class="d-flex align-items-center justify-content-between mb-0"
                            pills
                          >
                            <b-nav-item class="nav-btn bg-gray" active>
                              <div
                                class="d-flex flex-column align-items-center"
                              >
                                <h4 class="text-weight">
                                  {{ formatDate(birthday.birthday).day }}
                                </h4>
                                <span>{{
                                  formatDate(birthday.birthday).month
                                }}</span>
                              </div>
                            </b-nav-item>
                          </b-nav>
                        </div>
                        <div
                          class="main-box d-flex align-items-center flex-wrap"
                        >
                          <div
                            class="name-content w-auto"
                            style="cursor: pointer; margin-bottom: 0.5rem"
                          >
                            <b-badge
                              variant="lead-name"
                              class="name mr-1"
                              @click="goToDetail(birthday)"
                            >
                              <div
                                class="align-text-right text-capitalize text-wrap"
                                style="margin-bottom: 4px"
                              >
                                {{ birthday.name }}
                              </div>
                              <div
                                v-if="birthday.cell_phone"
                                class="align-text-top phone"
                              >
                                {{ birthday.cell_phone | formatPhone }}
                              </div>
                            </b-badge>
                          </div>
                          <div>
                            <b-badge pill variant="dash-customer-type">
                              {{ birthday.customer_type }}
                            </b-badge>
                          </div>
                        </div>
                      </div>
                      <div class="m-1">
                        <b-badge
                          variant="lead-name"
                          class="whats"
                          @click="openWhatsapp(birthday.cell_phone)"
                        >
                          <img
                            src="~@/assets/images/svg/icn_whats.svg"
                            width="18"
                          />
                        </b-badge>
                      </div>
                    </div>
                  </article>
                  <article
                    class="past-birthdays"
                    v-for="(birthday, index) in birthdaysPast"
                    :key="birthday.cell_phone + index"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center border-bottom p-2 dash-customer"
                    >
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <div class="profile-picture">
                          <b-nav
                            class="d-flex align-items-center justify-content-between mb-0"
                            pills
                          >
                            <b-nav-item class="nav-btn bg-gray" active>
                              <div
                                class="d-flex flex-column align-items-center"
                              >
                                <h4 class="text-weight">
                                  {{ formatDate(birthday.birthday).day }}
                                </h4>
                                <span>{{
                                  formatDate(birthday.birthday).month
                                }}</span>
                              </div>
                            </b-nav-item>
                          </b-nav>
                        </div>
                        <div
                          class="main-box d-flex align-items-start flex-column"
                        >
                          <div
                            class="name-content justify-content-start"
                            style="cursor: pointer; margin-bottom: 0.5rem"
                          >
                            <b-badge
                              variant="lead-name"
                              class="name"
                              @click="goToDetail(birthday)"
                            >
                              <div
                                class="align-text-right text-capitalize text-wrap"
                                style="margin-bottom: 4px"
                              >
                                {{ birthday.name }}
                              </div>
                              <div
                                v-if="birthday.cell_phone"
                                class="align-text-top phone"
                              >
                                {{ birthday.cell_phone | formatPhone }}
                              </div>
                            </b-badge>
                          </div>
                          <div>
                            <b-badge pill variant="dash-customer-type">
                              {{ birthday.customer_type }}
                            </b-badge>
                          </div>
                        </div>
                      </div>
                      <div class="m-1">
                        <b-badge
                          variant="lead-name"
                          class="whats"
                          @click="openWhatsapp(birthday.cell_phone)"
                        >
                          <img
                            src="~@/assets/images/svg/icn_whats.svg"
                            width="18"
                          />
                        </b-badge>
                      </div>
                    </div>
                  </article>
                </div>
              </section>
            </b-col>
          </b-collapse>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BBadge, BCol, BCollapse, BRow, BCard, BCardBody } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import * as customerTypes from "@/constants/customers_types";

export default {
  components: {
    BBadge,
    BCol,
    BCollapse,
    BRow,
    BCard,
    BCardBody,
  },
  data() {
    return {
      visible: true,
      loading: {
        birthdays: false,
      },
    };
  },

  setup() {
    const { skin } = useAppConfig();

    return { skin };
  },

  computed: {
    ...mapGetters({
      birthdaysThisMonth: types.BIRTHDAYS_THIS_MONTH,
    }),

    birthdaysPast() {
      return this.birthdaysThisMonth?.birthdays_past || [];
    },
    birthdaysFuture() {
      return this.birthdaysThisMonth?.birthdays_future || [];
    },
    birthdaysLength() {
      return this.birthdaysFuture?.length + this.birthdaysPast?.length;
    },
    useScrolled() {
      return (
        this.birthdaysLength > 3 || this.$store.state.app.windowWidth < 1360
      );
    },
  },

  mounted() {
    this.fetchAppointmentsFromToday();
  },

  methods: {
    ...mapActions({
      getBirthdaysThisMonth: types.GET_BIRTHDAYS_THIS_MONTH,
    }),

    fetchAppointmentsFromToday() {
      this.loading.birthdays = true;

      this.getBirthdaysThisMonth()
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar as agendas da semana. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.birthdays = false;
        });
    },

    formatDate(dateString) {
      const months = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ];
      const dateParts = dateString.split("/");
      return {
        day: dateParts[0],
        month: months[parseInt(dateParts[1]) - 1],
      };
    },

    formatPhoneNumber(phoneNumberString) {
      let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      let match;
      if (cleaned.length === 13) {
        match = cleaned.match(/^(\d{2})(\d{2})(\d{5})(\d{4})$/);
      } else if (cleaned.length === 12) {
        match = cleaned.match(/^(\d{2})(\d{2})(\d{4})(\d{4})$/);
      }
      if (match) {
        // return (
        //   "+" + match[1] + " (" + match[2] + ") " + match[3] + "-" + match[4]
        // );
        return " (" + match[2] + ") " + match[3] + "-" + match[4];
      }
      return null;
    },
    goToDetail(birthday) {
      var routeDetails = null;
      if (birthday.customer_type_id == customerTypes.CUSTOMER) {
        routeDetails = this.$router.resolve({
          name: "customer-profile",
          params: { id: birthday.id },
        });
      } else {
        routeDetails = this.$router.resolve({
          name: "lead-profile",
          params: { id: birthday.id },
        });
      }
      setTimeout(() => {
        window.open(routeDetails.href, "_blank");
      });
    },
    openWhatsapp(cell_phone) {
      setTimeout(() => {
        window.open("https://wa.me/" + cell_phone, "_blank").focus();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-profile.scss";
@import "@core/scss/views/dashboard/components.scss";

$grey: #636978;
$bg-badge: #64377d;

.nav-btn > .nav-link {
  padding: 0.75rem !important;
  cursor: auto;

  &.active {
    background-color: $grey !important;
    border: 1px solid $grey;
    box-shadow: none;

    h4 {
      color: $white;
    }
  }
}

.past-birthdays {
  filter: opacity(0.3);
}

.container-schedule {
  height: 392.77px;

  .user {
    span {
      margin-right: 1rem;
    }
    .type-user {
      margin-right: 0;
      background: $bg-badge !important;
    }
  }
}
</style>
