<template>
  <div>
    <appointment-report-details-sidebar />
    <b-card no-body>
      <b-card-body class="px-0 pb-0">
        <b-row>
          <b-col cols="12">
            <div
              :class="[
                'pb-2 px-2 d-flex justify-content-between',
                visible ? 'border-bottom' : '',
              ]"
            >
              <h4 class="mb-0">Agenda da semana</h4>
              <div class="type-interview d-flex">
                <div class="d-flex none-mobile">
                  <h6>
                    EA
                    <span class="mx-1">{{
                      totalizers.scheduled_interviews || 0
                    }}</span>
                  </h6>
                  <h6>
                    PA
                    <span class="mx-1">{{
                      totalizers.scheduled_partnership || 0
                    }}</span>
                  </h6>
                  <h6>
                    MXA
                    <span class="mx-1">{{
                      totalizers.scheduled_x_moment || 0
                    }}</span>
                  </h6>
                </div>
                <button
                  class="btn"
                  :aria-expanded="visible ? 'true' : 'false'"
                  aria-controls="collapse-1"
                  @click="visible = !visible"
                >
                  <feather-icon
                    :icon="visible ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                    class="mouse-pointer"
                    size="18"
                    stroke-width="3"
                  />
                </button>
              </div>
            </div>
          </b-col>
          <b-collapse v-model="visible" id="collapse-1" class="w-100">
            <b-col cols="12">
              <div class="py-2 px-2 px-lg-5">
                <b-nav class="d-flex justify-content-center mb-0" pills>
                  <b-nav-item
                    class="mr-1"
                    @click="defineStructure(ownOption)"
                    :active="activeStructure === ownOption"
                  >
                    Próprios
                  </b-nav-item>
                  <b-nav-item
                    @click="defineStructure(directOption)"
                    :active="activeStructure === directOption"
                  >
                    Equipe direta
                  </b-nav-item>
                  <b-nav-item
                    class="mr-1"
                    @click="defineStructure(structureOption)"
                    :active="activeStructure === structureOption"
                  >
                    Estrutura
                  </b-nav-item>
                </b-nav>
              </div>
            </b-col>

            <b-col cols="12">
              <div :class="['box-datecard', `bg-date-${skin}`]">
                <div v-if="currentMonth && yearOfWeek" class="mb-1">
                  <span class="text-weight">{{
                    currentMonth + " " + yearOfWeek
                  }}</span>
                </div>

                <b-nav class="grid-container align-items-center mb-0" pills>
                  <button
                    :class="['side-btn', `side-${skin}`]"
                    @click="prevWeek"
                  >
                    &#10094;
                  </button>
                  <div class="grid-day">
                    <b-nav-item
                      class="nav-btn"
                      v-for="(day, index) in daysOfWeek"
                      :key="index"
                      :active="index === activeDayIndex"
                      @click="selectDay(index)"
                    >
                      <div class="d-flex flex-column align-items-center">
                        <h4 class="text-weight">{{ day.date }}</h4>
                        <span> {{ day.abbreviatedDay }}</span>
                      </div>
                    </b-nav-item>
                  </div>

                  <button
                    :class="['side-btn', `side-${skin}`]"
                    @click="nextWeek"
                  >
                    &#10095;
                  </button>
                </b-nav>
                <!-- valor selecionado: {{ ownOption }} -->
              </div>
            </b-col>
            <b-col cols="12">
              <section class="container-schedule">
                <div
                  v-if="loading.daysOfWeek"
                  class="h-100 d-flex align-items-center justify-content-center p-2"
                >
                  <b-spinner variant="primary" />
                </div>
                <div
                  v-if="!items.length && !loading.daysOfWeek"
                  class="h-100 d-flex align-items-center justify-content-center p-2"
                >
                  <span class="text-center">
                    Não existem agendamentos para este dia
                  </span>
                </div>
                <div v-if="items.length && !loading.daysOfWeek">
                  <div
                    :class="[
                      'box-schedule h-100',
                      items.length > 3 ? 'scrolled' : '',
                      `scroll-${skin}`,
                    ]"
                  >
                    <div v-for="(appointment, index) in items" :key="index">
                      <article
                        class="d-flex justify-content-between align-items-center border-bottom p-2 cursor-pointer"
                        @click="openReportDetails(appointment.appointment_id)"
                      >
                        <div
                          class="d-flex justify-content-center align-items-center"
                        >
                          <div class="profile-picture">
                            <b-avatar
                              v-if="appointment.customer_profile_picture_url"
                              size="50"
                              variant="light-primary"
                              class="badge-minimal"
                            >
                              <img
                                :src="appointment.customer_profile_picture_url"
                                height="50"
                              />
                            </b-avatar>
                            <div
                              v-if="!appointment.customer_profile_picture_url"
                              class="pic-wout-img d-flex align-items-center justify-content-center"
                            >
                              <h2 class="text-weight">
                                {{ customerInitials[index] }}
                              </h2>
                            </div>
                          </div>
                          <div class="main-box">
                            <div class="title">
                              <h5
                                :title="appointment.customer_name"
                                class="mb-0"
                              >
                                {{ appointment.customer_name }}
                              </h5>
                            </div>
                            <div class="user mr-1 d-flex align-items-center">
                              <!-- <span>{{ appointment.customer_name }}</span> -->
                              <div class="type-user">
                                {{ appointment.appointment_status }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="schedule-time">
                          {{ appointment.appointment_start }}
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </section>
            </b-col>
            <!-- <b-col cols="12">
              <div class="p-2 pb-0 d-flex justify-content-end border-top">
                <a style="color: #f26237" @click="goToDetails">
                  Ver detalhes &#10095;
                </a>
              </div>
            </b-col> -->
            <b-col cols="12">
              <div class="py-1 d-flex justify-content-end border-top">
                <b-button variant="link" @click="goToDetails">
                  Ver detalhes &#10095;
                </b-button>
              </div>
            </b-col>
          </b-collapse>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import { OWN, STRUCTURE, DIRECT } from "@/constants/structure_options";
import AppointmentReportDetailsSidebar from "../../appointment/components/AppointmentReportDetailsSidebar.vue";
import { OPEN_APPOINTMENT_REPORT_DETAILS_SIDEBAR } from "../../appointment/store/types";

export default {
  components: {
    AppointmentReportDetailsSidebar,
  },
  data() {
    return {
      daysOfWeek: [],
      activeDayIndex: null,
      activeDate: null,
      weekOffset: 0,
      visible: true,
      loading: {
        daysOfWeek: false,
      },
      activeStructure: OWN,
    };
  },

  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },

  computed: {
    ...mapGetters({
      appointmentsFromToday: types.APPOINTMENTS_FROM_TODAY,
    }),

    ownOption() {
      return OWN;
    },

    structureOption() {
      return STRUCTURE;
    },

    directOption() {
      return DIRECT;
    },

    items() {
      return this.appointmentsFromToday?.data || [];
    },

    totalizers() {
      return this.appointmentsFromToday?.totalizers || {};
    },

    customerInitials() {
      return this.items.map((appointment) => {
        let names = appointment.customer_name.split(" ");
        if (names.length == 1) {
          return names[0].charAt(0);
        } else {
          return names[0].charAt(0) + names[names.length - 1].charAt(0);
        }
      });
    },

    currentMonth() {
      const monthIndex = new Date(
        this.daysOfWeek[this.activeDayIndex]?.fullDate
      ).getMonth();
      const monthNames = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];
      return monthNames[monthIndex];
    },

    yearOfWeek() {
      const yearIndex = new Date(
        this.daysOfWeek[this.activeDayIndex]?.fullDate
      ).getFullYear();

      return yearIndex;
    },
  },

  watch: {
    activeDate: function (value) {
      this.fetchAppointmentsFromToday();
    },
  },

  mounted() {
    this.getDaysOfWeek();
    this.fetchAppointmentsFromToday();
  },

  methods: {
    ...mapActions({
      postAppointmentsFromToday: types.POST_APPOINTMENTS_FROM_TODAY,
      openAppointmentReportDetailsSidebar:
        OPEN_APPOINTMENT_REPORT_DETAILS_SIDEBAR,
    }),

    goToDetails() {
      const activeDay = this.daysOfWeek[this.activeDayIndex];
      const activeDate = `${activeDay.fullDate.getFullYear()}-${String(
        activeDay.fullDate.getMonth() + 1
      ).padStart(2, "0")}-${String(activeDay.fullDate.getDate()).padStart(
        2,
        "0"
      )}`;

      let routeData = this.$router.push({
        path: "/compromissos",
        query: {
          startDate: activeDate,
          endDate: activeDate,
        },
      });

      // setTimeout(() => {
      //   window.open(routeData.href);
      // });
    },

    openReportDetails(id) {
      this.openAppointmentReportDetailsSidebar({ id });
    },

    defineStructure(value) {
      this.activeStructure = value;
      this.fetchAppointmentsFromToday();
    },

    fetchAppointmentsFromToday() {
      this.loading.daysOfWeek = true;
      const activeDay = this.daysOfWeek[this.activeDayIndex];
      const activeDate = `${activeDay.fullDate.getFullYear()}-${String(
        activeDay.fullDate.getMonth() + 1
      ).padStart(2, "0")}-${String(activeDay.fullDate.getDate()).padStart(
        2,
        "0"
      )}`;

      this.postAppointmentsFromToday({
        date: activeDate,
        structure_option: this.activeStructure,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar as agendas da semana. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.daysOfWeek = false;
        });
    },

    getDaysOfWeek() {
      const today = new Date();
      today.setDate(today.getDate() + this.weekOffset * 7);
      const daysOfWeek = [];

      const currentDayIndex = today.getDay();

      for (let i = 0; i < 7; i++) {
        const day = new Date(today);
        day.setDate(today.getDate() + (i - currentDayIndex));
        daysOfWeek.push({
          date: day.getDate(),
          fullDate: day,
          abbreviatedDay: day.toLocaleDateString("pt-BR", { weekday: "short" }),
        });
      }

      this.activeDayIndex = currentDayIndex;

      this.daysOfWeek = daysOfWeek;

      this.activeDate = this.daysOfWeek[currentDayIndex].date;
    },

    selectDay(index) {
      this.activeDayIndex = index;

      this.activeDate = this.daysOfWeek[index].date;
      this.fetchAppointmentsFromToday();
    },

    nextWeek() {
      this.weekOffset++;
      this.getDaysOfWeek();
    },

    prevWeek() {
      this.weekOffset--;
      this.getDaysOfWeek();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-profile.scss";
@import "@core/scss/views/dashboard/components.scss";

// .grid-day {
//   display: grid !important;
//   grid-template-columns: repeat(7, 1fr);
// }

// @media (max-width: 768px) {
//   .grid-day {
//     grid-template-columns: repeat(5, 1fr);
//   }
// }

// @media (max-width: 578px) {
//   .none-mobile {
//     display: none !important;
//   }

//   .grid-day {
//     grid-template-columns: repeat(4, 1fr);
//   }
// }
</style>
