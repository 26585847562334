<template>
  <div>
    <b-card no-body>
      <b-card-body class="p-0">
        <div 
          v-if="this.banners.length == 0"
          class="d-flex align-items-center justify-content-center p-2"
          :style="`height: ${width <= 576 ? '320px' : '270px'}`"
        >
          <b-spinner big variant="primary"/>
        </div>
        <b-carousel v-else id="carousel-fade" fade indicators controls :no-touch="false"
          :img-width="width <= 576 ? '320' : '1024'" :img-height="width <= 576 ? '320' : '270'">
          <div v-if="width > 576">
            <template v-for="banner in banners">
              <template v-if="banner.link_url">
                <a :href="banner.link_url" target="_blank" rel="noopener noreferrer">
                  <b-carousel-slide :img-src="banner.large_image_url" ></b-carousel-slide>
                </a>
              </template>
              <template v-else>
                <b-carousel-slide :img-src="banner.large_image_url" ></b-carousel-slide>
              </template>
            </template>
          </div>

          <div v-if="width <= 576">
            <template v-for="banner in banners">
              <template v-if="banner.link_url">
                <a :href="banner.link_url" target="_blank" rel="noopener noreferrer">
                  <b-carousel-slide :img-src="banner.small_image_url" ></b-carousel-slide>
                </a>
              </template>
              <template v-else>
                <b-carousel-slide :img-src="banner.small_image_url" ></b-carousel-slide>
              </template>
            </template>
          </div>
        </b-carousel>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import { useWindowSize } from "@vueuse/core";

export default {
  data() {
    return {
      visible: true,
      loading: {
        birthdays: false,
        banners: false,
      },
    };
  },

  setup() {
    const { skin } = useAppConfig();

    const { width, height } = useWindowSize();

    return { skin, width, height };
  },

  computed: {
    ...mapGetters({
      banners: types.BANNERS,
    }),
    dashBanner () {
      return this.banners
    }
  },  
  mounted() {
    this.get()
  },
  methods: {
    ...mapActions({
      getBanners: types.GET_BANNERS,
    }),
    get(){
      this.loading.banners = true
      this.getBanners()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os banners. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.banners = false;
        });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-profile.scss";
@import "@core/scss/views/dashboard/components.scss";

.carousel-fade {
  ::v-deep .carousel-item img {
    border-radius: 0.428rem !important;
  }

  ::v-deep .carousel-indicators li {
    margin-right: 20px !important;
    margin-left: 20px !important;
    border-radius: 50% !important;
  }

  ::v-deep .carousel-indicators li {
    width: 8px !important;
    height: 8px !important;
    background-color: #fff !important;
    opacity: 1 !important;
  }

  ::v-deep .carousel-indicators li.active {
    background-color: #f26237 !important;
    width: 10px !important;
    height: 10px !important;
  }

  ::v-deep a[aria-controls="carousel-fade___BV_inner_"] {
    width: 10%;
    opacity: 0.7;
    span {
      width: 30px;
      height: 30px;
      border-radius: 50px;
      border: 1px solid #0a0d14;
      background-color: #0a0d14;
    }
  }

  ::v-deep .carousel-control-prev-icon {
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23f26237" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"%3e%3cpolyline points="15 18 9 12 15 6"%3e%3c/polyline%3e%3c/svg%3e');
  }

  ::v-deep .carousel-control-next-icon {
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23f26237" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"%3e%3cpolyline points="9 18 15 12 9 6"%3e%3c/polyline%3e%3c/svg%3e');
  }
}
</style>
